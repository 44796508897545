<template>
  <a-modal
    :get-container="modalRef"
    v-model:visible="visible"
    :mask-closable="false"
    title="修改地磁"
    :after-close="afterClose"
  >
    <template #footer>
      <a-button :disabled="disabled" @click="visible = false">取消</a-button>
      <a-button :disabled="disabled" type="primary" @click="doSubmit"
        >确定</a-button
      >
    </template>
    <a-form :label-col="{ span: 4 }">
      <a-form-item label="设备名称" v-bind="validateInfos.name">
        <a-input
          v-model:value="geomagneticVo.name"
          :maxlength="16"
          placeholder="设备名称"
          allow-clear
        ></a-input>
      </a-form-item>
      <a-form-item label="机器码" v-bind="validateInfos.sn">
        <a-input
          v-model:value="geomagneticVo.sn"
          :maxlength="16"
          placeholder="机器码"
          allow-clear
        ></a-input>
      </a-form-item>
      <a-form-item label="所属车场" v-bind="validateInfos.parkingLotId">
        <a-select
          v-model:value="geomagneticVo.parkingLotId"
          notFoundContent="暂无数据"
          placeholder="请选择"
          @change="getParkingPlace"
          allow-clear
        >
          <a-select-option
            v-for="item in parkingLotList"
            :key="item.id"
            :value="item.id"
            >{{ item.name }}</a-select-option
          >
        </a-select>
      </a-form-item>
      <a-form-item label="泊位编号" v-bind="validateInfos.parkingPlaceId">
        <a-select
          v-model:value="geomagneticVo.parkingPlaceId"
          notFoundContent="暂无数据"
          placeholder="请选择"
          allow-clear
        >
          <a-select-option
            v-for="item in parkingPlaceList.list"
            :key="item.id"
            :value="item.id"
            >{{ item.code }}</a-select-option
          >
        </a-select>
      </a-form-item>
    </a-form>
  </a-modal>
</template>

<script>
import { ref, onMounted, toRefs, reactive } from "vue";
import useValidateRules from "@/hooks/useValidateRules";
import useMessage from "@/hooks/useMessage";
import { Form } from "ant-design-vue";
import { edit, detail } from "@/api/maintenance/geomagneticApi";
import useGeomagnetic from "../../../../hooks/maintenance/useGeomagnetic";
import { listAll } from "../../../../api/parking/parkingPlaceApi";

const useForm = Form.useForm;
export default {
  name: "Edit",
  props: ["modalRef", "id"],
  emits: ["close"],
  setup(props, { emit }) {
    const parkingPlaceList = reactive({ list: [] });
    const disabled = ref(false);
    const { getParkingLotList, parkingLotList, geomagneticVo } =
      useGeomagnetic();
    const { geomagneticVoRules } = useValidateRules();
    const { showErr, showConfirm, showLoading, closeLoading, showTipMessage } =
      useMessage();
    const { validateInfos, validate } = useForm(
      geomagneticVo,
      geomagneticVoRules,
    );
    const visible = ref(true);
    let refresh = false;
    const afterClose = () => {
      emit("close", refresh);
    };
    const doSubmit = () => {
      validate().then(async () => {
        let action = await showConfirm("更新地磁设备?");
        if (action) {
          disabled.value = true;
          await showLoading();
          try {
            let obj = {
              name: geomagneticVo.name,
              sn: geomagneticVo.sn,
              parkingLotId: geomagneticVo.parkingLotId,
              parkingPlaceId: geomagneticVo.parkingPlaceId,
            };
            await edit(props.id, obj);
            await closeLoading();
            await showTipMessage("修改成功");
            refresh = true;
            visible.value = false;
          } catch (e) {
            await closeLoading();
            await showErr(e);
            disabled.value = false;
          }
        }
      });
    };
    const getParkingPlace = async parkingLotId => {
      if (parkingLotId == null) {
        parkingPlaceList.list = []
        geomagneticVo.parkingPlaceId = null
        return ;
      }
      try {
        let { data } = await listAll(parkingLotId);
        parkingPlaceList.list = data;
      } catch (e) {
        showErr(e);
      }
    };
    onMounted(async () => {
      getParkingLotList();
      try {
        let { data } = await detail(props.id)
        geomagneticVo.name = data.name
        geomagneticVo.sn = data.sn
        geomagneticVo.parkingLotId = data.parkingLotId
        geomagneticVo.parkingPlaceId = data.parkingPlaceId
        getParkingPlace(geomagneticVo.parkingLotId)
      } catch (e) {
        showErr(e);
      }
    });
    return {
      ...toRefs(props),
      disabled,
      visible,
      afterClose,
      getParkingLotList,
      parkingLotList,
      parkingPlaceList,
      getParkingPlace,
      geomagneticVo,
      validateInfos,
      doSubmit,
    };
  },
};
</script>

<style scoped></style>
